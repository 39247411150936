<template>
  <h1 class="text-5xl text-left pl-20 pt-10">Runes ønsker</h1>
  <Cards :items="items" />
</template>

<script>
import Cards from './components/Cards.vue'

export default {
  name: 'App',
  components: {
    Cards,
  },
  data: function() {
    return {
      'items': [
        {
          'title': 'Lesebrett - Kobo Clara BW lesebrett, 6" skjerm',
          'href':  'https://www.clasohlson.com/no/Kobo-Clara-BW-lesebrett,-6&quot;-skjerm/p/39-5045-2',
          'image': 'https://images.clasohlson.com/medias/sys_master/h09/hca/68482120679454.jpg',
          'price': '1 649,-'
        },
        {
          'title': 'Kobo Clara - Utui fra Amazon',
          'href':  'https://www.amazon.co.uk/ERUNTO-released-Texture-Magnetic-Function/dp/B0CLGTZDST',
          'image': 'https://m.media-amazon.com/images/I/81G4+kV9JbL._AC_SX679_.jpg',
          'price': '200,-'
        },
        {
         'title': 'Hverdagssko 44.5',
         // 'href':  'https://www.xxl.no/asics-patriot-13-lopesko-herre-bla/p/1229773_1_Style',
         'image': 'https://img.freepik.com/free-photo/3d-render-blue-gift-box-with-ribbon-male-package_107791-16197.jpg',
         'price': '-'
        },
        {
          'title': 'Novablast 5 - Indigo-blue - 44.5',
          'href':  'https://www.zalando.no/asics-novablast-5-loepesko-for-vei-indigo-blueamber-as142a17k-k12.html',
          'image': 'https://img01.ztat.net/article/spp-media-p1/85eae93f07df43528a227132026bec15/6f18b719c0b14f5baf84dc3db9ed54ff.jpg?imwidth=1800&filter=packshot',
          'price': '1 695,-'
        },
        {
          'title': 'Klær: Sokker 44, boxere, T-skjorter (hvite, både runde og vhals)',
          'href': '',
          'image': 'https://lp2.hm.com/hmgoepprod?set=quality%5B79%5D%2Csource%5B%2F19%2Fa9%2F19a9ba8a2ac726e3e3f5222de380bdb5e7a8dac6.jpg%5D%2Corigin%5Bdam%5D%2Ccategory%5B%5D%2Ctype%5BDESCRIPTIVESTILLLIFE%5D%2Cres%5Bm%5D%2Chmver%5B2%5D&call=url[file:/product/fullscreen]',
          'price': '99.90 - 500,-'
        },
        {
          'title': 'Sykkelsørvis - 1-2 stk',
          'href':  '',
          'image': 'https://img6.custompublish.com/getfile.php/2997985.2126.xtbwxestbv/1500f0/5727899_2997985.jpg',
          'price': '-'
        },
        {
          'title': 'Akustisk gitar - Helland, etc',
          'href':  '',
          'image': 'https://img.freepik.com/free-photo/3d-render-blue-gift-box-with-ribbon-male-package_107791-16197.jpg',
          'price': '4500 - 5500,-'
        },
        {
          'title': 'Øyo Vinkelskraper - bestillingsvarer',
          'href':  '',
          'image': 'https://www.byggern.no/_next/image?url=https%3A%2F%2Feas-prod-censhare-assets.s3.eu-west-1.amazonaws.com%2F12%2F65%2F82%2F12658299.jpg&w=480&q=75',
          'price': '400,-'
        },
        {
          'title': 'Dørgym',
          'href':  'https://www.xxl.no/titan-life-multi-grip-door-gym-dorgym-svart/p/1215416_1_Style',
          'image': 'https://www.xxl.no/filespin/bfa554ed8e3c4208ab0da45d08ec5096?quality=75&resize=640%2C640',
          'price': '500,-'
        },

        //{
        //  'title': 'Sort paraply - Feks fra Swims',
        //  'href': '',
        //  'image': 'https://villoid-shop.imgix.net/s/files/1/1513/8164/products/PFjnollQSrWCrvEggo2t_43301_085_640x.jpg?v=1599686403&trim=color&trimtol=10&trimcolor=fff&fit=&w=&h=&auto=format',
        //  'price': '400 - 600,-'
        //},
        //{
        //  'title': 'Dreiejern',
        //  'href':  'https://www.tredreieutstyr.no/nettbutikk/dreiejern/startsett-dreiejern/komplett-startsett-6-jern-hamlet',
        //  'image': 'https://www.tredreieutstyr.no/image/startsett-dreiejern/150hct6-dsc00995.jpg?width=500&pad=true',
        //  'price': '2295,-'
        //},
      ],
    }
  }
}
</script>

<style>
</style>
